<template>
 <div class="v_bussiness_change_pwd g-flex-align-center g-flex-justify-center">
   <div class="v-bussiness-change-pwd-form">
     <div class="v-bussiness-change-pwd-form-head g-flex-align-center g-flex-justify-center">
       <p class="v-bussiness-change-pwd-form-head-title">修改密码</p>
     </div>
     <el-form :model="form" ref="form" label-width="120px">
       <div class="v-bussiness-change-pwd-form-top">
        <el-form-item label="账号:" prop="user_name">
          <el-input type="text" placeholder="请输入账号" readonly v-model="accountName" autocomplete="off"/>
        </el-form-item>
        <el-form-item label="原密码:" prop="user_name">
          <el-input :type="isPwdType ? 'password': ''" placeholder="请输入原密码" v-model="form.old_password" autocomplete="off">
            <i slot="suffix" @click="toggleType" class="iconfont" :class="isPwdType ? 'icon-bukejian' : 'iconmimakejianguanbi'"></i>
          </el-input>
        </el-form-item>
       </div>

      <div class="v-bussiness-change-pwd-form-bottom">
        <el-form-item label="新密码:" prop="password">
          <el-input :type="isPwdTypeThird ? 'password': ''"  placeholder="请输入密码" v-model="form.new_password" autocomplete="off">
              <i slot="suffix" @click="toggleTypeThird" class="iconfont" :class="isPwdTypeThird ? 'icon-bukejian' : 'iconmimakejianguanbi'"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="确认新密码:" prop="password">
          <el-input :type="isPwdTypeFour ? 'password': ''"  placeholder="请输入密码" v-model="form.newSecondPassword" autocomplete="off">
              <i slot="suffix" @click="toggleTypeFour" class="iconfont" :class="isPwdTypeFour ? 'icon-bukejian' : 'iconmimakejianguanbi'"></i>
          </el-input>
        </el-form-item>
      </div> 
      <div class="v-bussiness-change-pwd-form-btn">
        <el-button type="primary" @click="apiChangeShopPasswordHandel">确认修改</el-button>
      </div>
     </el-form>
   </div>
 </div>
</template>

<script>
import { apiChangeShopPassword } from '@/utils/api'
export default {
  data() {
    return {
      isPwdType: true,
      isPwdTypeSecond: true,
      isPwdTypeThird: true,
      isPwdTypeFour: true,
      form: {
        old_password:'',
        new_password: '',
        newSecondPassword: ''
      }
    }
  },
  methods: {
    toggleType() {
      this.isPwdType = !this.isPwdType
    },
    toggleTypeSecond() {
      this.isPwdTypeSecond = !this.isPwdTypeSecond
    },
    toggleTypeThird() {
      this.isPwdTypeThird = !this.isPwdTypeThird
    },
    toggleTypeFour() {
      this.isPwdTypeFour = !this.isPwdTypeFour
    },
    async apiChangeShopPasswordHandel() {
      if(!this.form.old_password) return this.$message.error('请输入原密码')
      if(!this.form.new_password) return this.$message.error('请输入新密码')
      if(!this.form.newSecondPassword) return this.$message.error('请输入确认密码')
      if(this.form.new_password != this.form.newSecondPassword) return this.$message.error('请确认两次密码是否输入一致')
      let loading = this.$loading()
      const { success, data } = await apiChangeShopPassword(this.form).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
    }
  },
  computed: {
    accountName() {
      if(this.$global.type == 2) return this.$global.adminUser.mobile
      return this.$global.adminUser.user_name
    }
  }
}
</script>

<style lang='scss'>
.v_bussiness_change_pwd {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url('/img/changpwd_bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .v-bussiness-change-pwd-form {
    width: 800px;
    height: 500px;
    background: $white;
    border-radius: 8px;
    box-shadow: 0px 4px 12px 10px rgba(194, 197, 251, 0.2);
    overflow: hidden;
    .v-bussiness-change-pwd-form-head {
      background: url('/img/change_pwd_head.png');
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      border-radius: 8px 8px 0 0;
      height: 80px;
      width: 100%;
      .v-bussiness-change-pwd-form-head-title {
        font-size: 24px;
        letter-spacing: 2px;
        color: $white;
      }
    }
    .el-form {
      padding: 40px 60px 20px 60px;
      .el-form-item {
        .el-form-item__label {
          font-size: 18px;
        }
        .iconfont {
          font-size: 30px;
          cursor: pointer;
        }
      }
      .v-bussiness-change-pwd-form-top {
        padding: 0 100px 10px 100px;
        border-bottom: 2px dashed #DCDCDC;
      }
      .v-bussiness-change-pwd-form-bottom {
        padding: 30px 100px 10px 100px;
      }
      .v-bussiness-change-pwd-form-btn {
        padding: 0px 120px 10px 220px;
        .el-button {
          width: 100%;
        }
      }
    }
  }
}
</style>